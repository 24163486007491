import React from 'react'
import './Form.css';
import Form from './Form.js';

export default function FormSection() {
  return (
    <div className='form-section'>
        <div className='form-content'>
            <div className='form-text'>
                <h1>Access to a Diverse Lender Network</h1>
                <p>Lyon Loans is your gateway to a vast network of lenders, offering instant pre-approval through a single, secure application. With over 35 lending partners, we provide access to unsecured loans up to $100,000, catering to borrowers of all credit profiles.</p>
            </div>
            <div className='form-text'>
                <h1>Flexible Loan Options</h1>
                <p>From small cash needs to debt consolidation, Lyon Loans connects you with the ideal lender offering loans ranging from $500 to $100,000. Our streamlined process ensures checking for offers has zero impact on your credit score, making borrowing stress-free and convenient.</p>
            </div>
        </div>
        <Form />
    </div>
  )
}
