import React from 'react'
import './Form.css';
import { useForm, ValidationError } from '@formspree/react';

export default function Form() {
    const [ state, handleSubmit ] = useForm("mrgnkydy");

    if (state.succeeded) {
        return (
            <div>
                <h1>Thanks for requesting a callback!</h1>
                <p>A team member will get in touch with you shortly.</p>
            </div>
        )
    }
  return (
    <div className='form-container'>
        <h1 className='form-header'>Request a Callback</h1>
        <form
            className='form'
            onSubmit={handleSubmit}
        >
            <div className='form-group'>
                <label htmlFor="first-name">First Name</label>
                <input
                    id="first-name"
                    name="first-name"
                    placeholder='ex: John'
                    required
                />
                <ValidationError
                    prefix="FirstName"
                    field="first-name"
                    errors={state.errors}
                />
            </div>
            <div className='form-group'>
                <label htmlFor="last-name">Last Name</label>
                <input
                    id="last-name"
                    name="last-name"
                    placeholder='ex: Doe'
                    required
                />
                <ValidationError
                    prefix="LastName"
                    field="last-name"
                    errors={state.errors}
                />
            </div>
            <div className='form-group'>
                <label htmlFor="email">Email</label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="ex: johndoe@gmail.com"
                    required
                />
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
            </div>
            <div className='form-group'>
                <label htmlFor="phone">Phone Number</label>
                <input
                    id="phone"
                    type="tel"
                    name="phone"
                    placeholder="123-123-1234" pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    required
                />
                <ValidationError
                    prefix="Phone"
                    field="phone"
                    errors={state.errors}
                />
            </div>
            <div className='form-group'>
                <label htmlFor="loan-options">Loan Amount Requested</label>
                <select id="loan-options" name="loan-options">
                    <option value="Less than $10,000">Less than $10,000</option>
                    <option value="$10,000 - $15,000">$10,000 - $15,000</option>
                    <option value="$15,000 - $25,000">$15,000 - $25,000</option>
                    <option value="$25,000 - $50,000">$25,000 - $50,000</option>
                    <option value="Greater than $50,000">Greater than $50,000</option>
                </select>
            </div>

            <button
                className='submit'
                type="submit"
                disabled={state.submitting}
            >
                Get My Offers!
            </button>
        </form>
    </div>
  )
}
