import React from 'react'
import './Disclaimer.css';

export default function Disclaimer() {
  return (
    <div className='disclaimer-container'>
      <p className='disclaimer-text'>
        This offer pertains to an unsecured personal loan referred or offered by Lyon Loans. All personal loan requests submitted through Lyon Loans are funded by a third party. Lyon Loans does not control participating third-party lenders' credit eligibility criteria. APR/Interest rates will vary depending on individual lender terms. Generally, all individuals applying for credit must be a U.S. citizen or permanent resident and at least 18 years old. Valid bank account and Social Security Number/FEIN are required. All loan applications are subject to credit review and approval.
      </p>
      <p className='disclaimer-text'>
        You authorize us to make inquiries and obtain and confirm information about you as we deem appropriate for the purpose of evaluating your eligibility for this extension of credit. Not all borrowers will qualify for the same rate when a rate is listed “as low as”. Qualifying for rates listed “as low as” are based on the borrower’s credit worthiness, which includes factors such as debt ratio and credit history. Interest rates will vary and are based on lender terms. Ranges from 7.99%-36%. All loans may be subject to origination fees ranging from 1% – 5%, of the loan amount, if permitted under applicable state law.
      </p>
      <p className='disclaimer-text'>
        We will never discriminate on the basis of race, color, religion, sex, marital status, national origin or ancestry.
      </p>

      <p className='disclaimer-text centered'>
        Copyright © 2024 Lyon Loans. All rights reserved
      </p>
    </div>
  )
}
