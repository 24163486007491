import React from 'react'
import './Header.css';
import lyonLogo from '../../assets/lyon-logo.png';

export default function Header() {
  return (
    <div className='header'>
        <div className='header-container'>
            <div className='logo-container'>
                <img src={lyonLogo} className='logo-img' />
                <span className='logo-text logo-accent'>Lyon&nbsp;</span> <span className='logo-text logo-primary'>Loans</span>
            </div>

            <div className='phone-container'>
                <i class="fa-solid fa-phone fa-xl"></i>
                <p className='phone'>(888)-686-6627</p>
            </div>
        </div>
    </div>
  )
}
