import React from "react";

export default function StepCard({ instruction, stepNumber, stepImage }) {
	return (
		<div className="step-card">
            <div className="step-top">
                <span className="step-number">{ stepNumber }</span>
	    		<h3>{ instruction }</h3>
            </div>
            <div className="image-container">
                <img src={stepImage} />
            </div>
		</div>
	);
}
