import React from 'react'
import './Steps.css';
import StepCard from './StepCard';
import step1 from '../../assets/step1.png'
import step2 from '../../assets/step2.png';
import step3 from '../../assets/step3.png';

export default function Steps() {
  return (
    <div className='steps-section'>
        <h1>Securing a Personal Loan is a breeze with Lyon!</h1>
        <p>Experience our streamlined process: Start by contacting us, then fill out our form, and within moments, you'll be presented with your tailored loan options!</p>
        <div className='step-cards'>
            <StepCard instruction="Give us a call." stepNumber={1} stepImage={step1} />
            <StepCard instruction="Complete our form." stepNumber={2} stepImage={step2} />
            <StepCard instruction="View your loan offers instantly." stepNumber={3} stepImage={step3} />
        </div>
    </div>
  )
}
