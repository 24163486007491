import Disclaimer from "./components/Disclaimer/Disclaimer";
import FormSection from "./components/Form/FormSection";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Steps from "./components/Steps/Steps";


function App() {
  return (
    <>
      <Header />
      <Hero />
      <Steps />
      <FormSection />
      <Disclaimer />
    </>
  );
}

export default App;
