import React from 'react'
import './Hero.css';

import sofi from '../../assets/sofi-logo.webp';
import upstart from '../../assets/upstart-logo.webp';
import upgrade from '../../assets/upgrade-logo.webp';
import lendingClub from '../../assets/lendingclub-logo.webp';
import prosper from '../../assets/prosper-logo.webp';

export default function Hero() {
  return (
    <div>
        <div className='hero-container'>
            <div className='hero-text'>
                <div className='filter'></div>
                <h1 className='hero-tagline'>Personalized loans, tailored to you.</h1>
                <p className='hero-p'>1 application <span className='divider-color'> | </span> 35+ Lending Partners <span className='divider-color'> | </span> $2.5B+ Total Loan Approvals</p>
                <span className='divider'></span>
                <div className='lender-logos'>
                    <img src={sofi} className='colorMask' />
                    <img src={upstart} className='colorMask' />
                    <img src={upgrade} className='colorMask' />
                    <img src={lendingClub} className='colorMask' />
                    <img src={prosper} className='colorMask' />
                    <p className='hero-ps'>+ Many More</p>
                </div>
            </div>
        </div>
        <div className='hero-background'></div>
    </div>
  )
}
